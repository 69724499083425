import {addEvent} from 'buikit/src/assets/js/utils/eventHandling';
import {getFieldValue, getFormData,} from 'buikit/src/assets/js/utils/formHandling';
import queryString from 'query-string';
import transform from 'buikit/src/assets/js/utils/transform';
import RectHighlighter from '../../assets/js/utils/RectHighlighter';
import fetchUrl from 'buikit/src/assets/js/utils/fetchUrl';
import {isVisible} from "../../assets/js/utils/AISearchVisibilityController";

export default function Opener({container, history}) {
    const parser = new DOMParser();
    const self = this;
    const modelQuerySelector = '[name=cat_models]';
    this.initialize = () => {
        this.container = container;
        globalThis.aiSearchWidgetConfig.addListener(this.handleAISearchVisibility);
        this.form = container.querySelector('form');
        this.modelInput = this.form.querySelector(modelQuerySelector);
        this.makeInput = this.form.querySelector('[name=cat_brands]');
        this.eventListeners = [];
        if (this.modelInput && this.makeInput) {
            this.eventListeners.push(
                addEvent(this.makeInput, 'change', () => {
                    const makeId = getFieldValue(this.makeInput);
                    this.performeRestCall(this.form, makeId);
                })
            );
        }
        this.eventListeners.push(addEvent(this.form, 'submit', this.pushData));
        self.swipeCards = [];
        self.timeouts = [];
        container.querySelectorAll('.opener__car-card').forEach((card) => {
            const swipeCard = {
                remove(diff = 0) {
                    card.classList.add('move');
                    transform(
                        card,
                        `translateX(${diff < 0 ? diff - 200 : diff + 200}px)`
                    );
                    card.style.opacity = 0.001;
                    self.timeouts.push(
                        setTimeout(function () {
                            card.remove();
                        }, 360)
                    );
                },
            };
            this.eventListeners.push(
                addEvent(
                    card.querySelector('.s-car-card__close'),
                    'click',
                    () => {
                        // load new card
                        swipeCard.remove();
                    }
                )
            );
        });

        // highlightning

        this.adviceLinks = [
            ...container.querySelectorAll('.opener__advice-links a'),
        ];

        this.adviceLinks.forEach((link) => {
            this.eventListeners.push(
                addEvent(link, 'click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const target = e.target.getAttribute('href').slice(1);
                    const targetElem = document.getElementById(target);
                    if (!targetElem) {
                        return false;
                    }
                    if (target === 'suche') {
                        // don’t scroll
                        new RectHighlighter([targetElem], {
                            offClass: 'opener__highlight-off',
                            onClass: 'opener__highlight-on',
                            delay: 1000,
                        });
                    } else {
                        const position = targetElem.getBoundingClientRect();
                        window.scroller.top(
                            position.top + window.pageYOffset - 135,
                            {
                                oncomplete() {
                                    new RectHighlighter([targetElem], {
                                        offClass: 'opener__highlight-off',
                                        onClass: 'opener__highlight-on',
                                        delay: 1000,
                                    });
                                },
                            }
                        );
                    }
                })
            );
        });
    };
    this.handleAISearchVisibility = () => {
        const classicOpenerBanner = document.querySelector('.opener__classic-banner');
        const aiSearchOpenerBanner = document.querySelector('.opener__ai-search-banner');
        const widgetVisibility = isVisible();
        if (classicOpenerBanner && aiSearchOpenerBanner) {
            if (widgetVisibility) {
                classicOpenerBanner.style.display = 'none';
                aiSearchOpenerBanner.style.display = 'inline';
            } else {
                classicOpenerBanner.style.display = 'inline';
                aiSearchOpenerBanner.style.display = 'none';
            }
        }
    }
    this.performeRestCall = (form, brand) => {
        const namespace = form.dataset.openerNamespace;
        const params = {
            [`${namespace}:brand`]: brand,
        };
        fetchUrl({
            params,
            url: form.action,
            method: form.method,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
            },
        }).then((response) => {
            if (self.bui) self.bui.finalize();
            if (self.tween) self.tween.kill();

            const dom = parser.parseFromString(response.data, 'text/html');
            const newTitleContainer = dom.querySelector(modelQuerySelector);

            self.modelInput = self.container.querySelector(modelQuerySelector);
            self.modelInput.innerHTML = newTitleContainer.innerHTML;
        });
    };
    this.pushData = (e) => {
        e.preventDefault();
        const data = getFormData(this.form);
        for (let key in data) {
            if (Array.isArray(data[key])) {
                data[key] = data[key].sort().join(',');
            }
        }
        history.push({
            pathname: this.form.getAttribute('data-search-link'),
            search: queryString.stringify(data),
        });
        return false;
    };
    this.finalize = () => {
        if (this.swiper) {
            this.swiper.destroy();
        }
        if (this.eventListeners.length) {
            this.eventListeners.forEach((event) => event.remove());
        }
        window.scroller.clear();
    };
    this.initialize();
}
